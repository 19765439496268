import { RadioGroup, RadioOption } from './RadioGroup'
import React, { useState } from 'react'

/**
 * This is antipattern from ancient times
 * this is documented already as such in react docs
 * https://react.dev/reference/react/cloneElement
 *
 * @constructor
 */
function RadioGroupCloneApp() {
    const [selected, setSelected] = useState('')
    return (
        <div className="App">
            <h2>How did you hear about Little Lemon?</h2>
            <RadioGroup onChange={setSelected} selected={selected}>
                <RadioOption value="social_media">Social Media</RadioOption>
                <RadioOption value="friends">Friends</RadioOption>
                <RadioOption value="advertising">Advertising</RadioOption>
                <RadioOption value="other">Other</RadioOption>
            </RadioGroup>
            <button disabled={!selected}>Submit</button>
        </div>
    )
}

export default RadioGroupCloneApp
